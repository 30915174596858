<template>
    <v-btn
        v-if="$vuetify.breakpoint.smAndUp"
        @click="$emit('click')"
        color="primary"
        rounded
        :to="route"
    >
        <v-icon>mdi-plus</v-icon> Novo
    </v-btn>
    <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="$emit('click')"
                color="primary"
                fab
                small
                :to="route"
                v-bind="attrs" v-on="on"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        Novo cadastro
    </v-tooltip>
</template>

<script>
export default {
    name: 'ButtonAddNew',

    props: ['route'],
}
</script>
